window.addEventListener('load', () => {
  discoverBlock.init();
});

const discoverBlock = {
  init: function () {
    const sections = document.querySelectorAll('[data-discover-block-js]');

    if (!sections.length) return;

    sections.forEach((section) => {
      this.itemsHover(section);
    })

  },

  itemsHover: function (section) {
    let items = section.querySelectorAll('.discover-block__item');
    if (!items || items.length === 0) return;
    items.forEach((item) => {
      let link = item.querySelector('a');
      if (!link) return;
      link.addEventListener('mouseover', () => {
        item.classList.add('active');
      })
      link.addEventListener('mouseleave', () => {
        item.classList.remove('active');
      })
    })
  },

}

export default discoverBlock;
